import React, { useState } from 'react'
import { FaReact, FaGithub } from "react-icons/fa6";

import './App.css';


function App() {

  const [style, setStyle] = useState("no-clicked");
  const [style2, setStyle2] = useState("no-clicked");
  const [style3, setStyle3] = useState("no-clicked");
  const [style4, setStyle4] = useState("no-clicked");

  const changeStyle = () => {
    console.log("about clicked");

    if (style === "no-clicked") {
      setStyle("clicked");
    }
    else if (style === "clicked") {
      setStyle("no-clicked");
    }

    setStyle2("no-clicked");
    setStyle3("no-clicked");
    setStyle4("no-clicked");
  };

  const changeStyle2 = () => {
    console.log("skills clicked");

    if (style2 === "no-clicked") {
      setStyle2("clicked");
    }
    else if (style2 === "clicked") {
      setStyle2("no-clicked");
    }

    setStyle("no-clicked");
    setStyle3("no-clicked");
    setStyle4("no-clicked");
  };

  const changeStyle3 = () => {
    console.log("projects clicked");

    if (style3 === "no-clicked") {
      setStyle3("clicked");
    }
    else if (style3 === "clicked") {
      setStyle3("no-clicked");
    }
    setStyle("no-clicked");
    setStyle2("no-clicked");
    setStyle4("no-clicked");
  };

  const changeStyle4 = () => {
    console.log("contact clicked");

    if (style4 === "no-clicked") {
      setStyle4("clicked");
    }
    else if (style4 === "clicked") {
      setStyle4("no-clicked")
    }
    setStyle("no-clicked");
    setStyle2("no-clicked");
    setStyle3("no-clicked");
  };

  return (
    <div className="App">

      <header className="Header open-sans">

        <h1 className="Title">Portfolio spizgany_filip</h1>

      </header>

      <nav className="navbar">

        <button className="Button open-sans" onClick={changeStyle} ><i className="fa fa-user" aria-hidden="true"></i>&nbsp;O mnie</button>
        <button className="Button open-sans" onClick={changeStyle2} ><i className="fa fa-code" aria-hidden="true"></i>&nbsp;Umiejętności</button>
        <button className="Button open-sans" onClick={changeStyle3} ><i className="fa fa-laptop" aria-hidden="true"></i>&nbsp;Projekty</button>
        <button className="Button open-sans" onClick={changeStyle4} ><i className="fa fa-envelope" aria-hidden="true"></i>&nbsp;Kontakt</button>

      </nav>

      <div className="Descriptions">

        <div className={`about-description ${style}`} id="description">
          Filip, 15 lat
        </div>

        <div className={`skills-description ${style2}`} id="description">
          <div className="cards">

            <div className="html-card" id="card">
              <i className="fa fa-html5" aria-hidden="true"></i>
              &nbsp;
              HTML
            </div>
            <div className="css-card" id="card">
              <i className="fa-brands fa-css3-alt"></i>
              &nbsp;
              CSS
            </div>
            <div className="react-card" id="card">
              <FaReact />
              &nbsp;
              REACT
            </div>
            <div className="js-card" id="card">
              <i className="fa-brands fa-js"></i>
              &nbsp;
              JS
            </div>
            <div className="python-card" id="card">
              <i className="fa-brands fa-python"></i>
              &nbsp;
              Python
            </div>
            <div className="dcpy-card" id="card">
              <i className="fa-brands fa-discord"></i>
              &nbsp;
              DCPY
            </div>
            <div className="sql-card" id="card">
              <i className="fa fa-database" aria-hidden="true"></i>
              &nbsp;
              SQL
            </div>

          </div>
        </div>

        <div className={`projects-description ${style3}`} id="description">
          <a href="https://github.com/spizganyfilip" id="github"><FaGithub />&nbsp;github.com/spizgany_filip</a>
        </div>

        <div className={`contact-description ${style4}`} id="description">
          <i className="fa fa-envelope" aria-hidden="true"></i>&nbsp; filip.craqow@gmail.com <br></br>
          <i className="fa-brands fa-discord"></i> spizgany_filip
        </div>

      </div>

    </div >
  );
}

export default App;